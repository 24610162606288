import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Toastr } from "src/app/core/plugins/toastr/toastr";
import { UserService } from "src/app/core/services/user.services";
import { Helper } from "src/app/core/utilities/helpers";
import { Storage } from "src/app/core/utilities/storage";

@Component({
    selector: "switch-profile-modal",
    templateUrl: "./switch-profile.component.html",
    providers: [],
})

export class SwitchProfileModal implements OnInit {
    public userRoles: string[] = [];
    public selectedValue: string = ""; 
    public allRoles:any[];
    public loading: boolean = false;
   
constructor(
public modalRef: BsModalRef,
public userService: UserService,
private router: Router,
public toastr: Toastr,
public storage: Storage
){}

    ngOnInit() {
       this.getRoles(); 
       this.getUserRoles();
    }

    getRoles(){
       this.userRoles =  localStorage.getItem("userRoles").split(',');
    }

    getUserRoles(){
  
        let sort_by = "";
        const allRoles = this.storage.get("authUser");    
        const userId = allRoles.id;
    
        this.userService
          .getUserRole(userId)
          .subscribe(
            (res) => {
              this.allRoles =res.data;    
            },
            (error) => {
              this._stopLoading();
              console.log(error);
            }
          );
      }
    

    closeModal(modalRef: any) {
        modalRef.hide();
      }

    _onRoleSelection(){
      Helper.onRoleSelection(this.selectedValue, this.allRoles, this.modalRef,this.router,this.toastr)
    }  

      _startLoading() {
        this.loading = true;
      }
    
      _stopLoading() {
        this.loading = false;
      }
      
}

