import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class ApiRoutes {
  public url: string;
  private baseUrl = environment.server.baseUrl;
  private apiVersionUrl = environment.server.apiVersionPath;

  public default_error_message =
    "Something went wrong, don't worry our engineers are fixing it right now.";

  get getBaseUrl(): string {
    return this.baseUrl + this.apiVersionUrl;
  }

  getUpdateTokenUrl(userId) {
    return `${this.getBaseUrl}/users/${userId}/fcm-token`;
  }

  getPostDocumentDraftsUrl(userId, studentId, collegeId, documentID) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${collegeId}/documents/${documentID}/drafts`;
  }

  get loginUrl() {
    return `${this.getBaseUrl}/login?includes=`;
  }
  get getLogoutUrl() {
    return `${this.getBaseUrl}/logout`;
  }
  getForgotPasswordUrl() {
    return `${this.getBaseUrl}/forgot-password`;
  }
  getResetPasswordUrl() {
    return `${this.getBaseUrl}/reset-password`;
  }
  getStudentListUrl(userId, page = null) {
    if (page == null) {
      page = 1;
    }
    return `${this.getBaseUrl}/admins/${userId}/students?includes=counsellors&page=${page}`;
  }
  getReadConversationUrl(id, groupId) {
    return `${this.getBaseUrl}/users/${id}/conversation_groups/${groupId}`;
  }
  getAddStudentUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/students`;
  }
  getAddStudentFileUploader(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students`;
  }
  getAddStudentUrlCounsellorManager(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students`;
  }
  getAddCounsellorUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors`;
  }
  getAddVisaCounsellorUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellors`;
  }
  getAddInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_trainers`;
  }
  getAddCounsellingManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellor_managers`;
  }
  getAddVisaCounsellingManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellor_managers`;
  }
  getAddInterviewTrainerManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_managers`;
  }
  getAddWriterUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/writers`;
  }
  getAddWriterManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/writer_managers`;
  }
  getAddManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/managers`;
  }
  getStudentLogsUrl(adminId, studentId) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/logs`;
  }
  getWriterStudentLogsUrl(userId, studentId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/logs`;
  }
  getWriterManagerStudentLogsUrl(userId, studentId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/logs`;
  }
  getStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}?includes=counsellors`;
  }
  getStudentCounsellingManagerUrl(userId, studentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}?includes=counsellors`;
  }
  getAllCounsellingManagerUrl(userId, page = null) {
    if (page == null) {
      page = 1;
    }
    return `${this.getBaseUrl}/admins/${userId}/counsellor_managers?page=${page}`;
  }
  getAssociateStudentCounsellingManagerUrl(studentId, counsellingManagerId) {
    return `${this.getBaseUrl}/counsellor_managers/${counsellingManagerId}/students/${studentId}/associate`;
  }
  getStudentWriterManagerUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}?includes=writer_managers`;
  }
  getAllWriterManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/writer_managers`;
  }
  getAssociateStudentWriterManagerUrl(studentId, writerManagerId) {
    return `${this.getBaseUrl}/writer_managers/${writerManagerId}/students/${studentId}/associate`;
  }
  getStudentCounsellorUrl(userId, studentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}?includes=counsellors`;
  }
  getStudentInterviewTrainerUrl(userId, studentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}?includes=interview_trainer`;
  }
  getCounsellingManagerStudentCounsellorUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}?includes=counsellors`;
  }
  getInterviewTrainerManagerStudentCounsellorUrl(userId, studentId) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}?includes=counsellors`;
  }
  getAllCounsellorUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors`;
  }
  getAllVisaCounsellorUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellors`;
  }
  getAllInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_trainers`;
  }
  getAllInterviewTrainerManagerInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/interview_managers/${userId}/interview_trainers`;
  }
  getAllVisaCounsellorManagerVisaCounsellorsUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/visa_counsellors`;
  }
  getCounsellingManagerCounsellorUrl(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors`;
  }
  getInterviewTrainerManagerInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/interview_managers/${userId}/interview_trainers`;
  }
  getAssociateStudentCounsellorUrl(studentId, counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/associate`;
  }
  getAssociateStudentDocumentWriterUrl(adminId, studentId, writerId) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/student_program_documents/${writerId}/associate`;
  }
  getAssociateWriterManagerStudentDocumentWriterUrl(
    userId,
    studentId,
    writerId
  ) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/student_program_documents/${writerId}/associate`;
  }
  getStudentWriterUrl(studentId) {
    return `${this.getBaseUrl}/students/${studentId}?includes=writers`;
  }
  getAllWritersUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/writers`;
  }
  getWriterManagerAllWritersUrl(userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/writers`;
  }
  getstudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getWriterManagerStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getWriterStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }

  getCounsellorStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getVisaCounsellorStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getInterviewTrainerStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getCounsellingManagerStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getCounsellingManagerStudentAllQualityCheckCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/type/qc_check`;
  }

  getVisaCounsellingManagerStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getInterviewTrainerManagerStudentAllShortlistedCollegesUrl(
    studentId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getstudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/type/my`;
  }
  getWriterManagerStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/type/my`;
  }
  getWriterStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/type/my`;
  }
  getstudentCounsellingManagerAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/type/my`;
  }
  getstudentVisaCounsellingManagerAllSelfShortlistedCollegesUrl(
    studentId,
    userId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/type/my`;
  }
  getstudentInterviewTrainerManagerAllSelfShortlistedCollegesUrl(
    studentId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/type/my`;
  }
  getCounsellorStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/type/my`;
  }
  getVisaCounsellorStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students/${studentId}/programs/type/my`;
  }
  getInterviewTrainerStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/type/my`;
  }
  getstudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/${programId}`;
  }
  getWriterManagerStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getWriterStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getCounsellorStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}`;
  }
  getVisaCounsellorStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students/${studentId}/programs/${programId}`;
  }
  getInterviewTrainerStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getInterviewTrainerStudentInterviewSessionsUrl(
    studentId,
    interviewId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getInterviewTrainerStudentInterviewSessionsFeedbackUrl(
    studentId,
    interviewId,
    interviewSessionId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/interviews/${interviewId}/interview_sessions/${interviewSessionId}/feedback`;
  }
  getCreateInterviewTrainerStudentInterviewSessionsUrl(
    studentId,
    interviewId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  createVisaCounsellorStudentVisaSessionUrl(studentId, visaId, userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students/${studentId}/visas/${visaId}/visa_sessions`;
  }
  getCounsellingManagerStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/${programId}`;
  }
  // counsellor Manager
  getCounsellingStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}`;
  }
  getVisaCounsellingManagerStudentProgramDetailsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getInterviewTrainerManagerStudentProgramDetailsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getVisaCounsellorManagerStudentProgramDetailsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/${programId}`;
  }
  getstudentProgramDocumentsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getWriterManagerStudentProgramDocumentsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getCounsellorStudentProgramDocumentsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getInterviewTrainerStudentProgramDocumentsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getCounsellingManagerStudentProgramDocumentsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getVisaCounsellingManagerStudentProgramDocumentsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getInterviewTrainerManagerStudentProgramDocumentsUrl(
    studentId,
    programId,
    userId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getStudentProgramDocumentDraftsUrl(studentId, programId, userId, documentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getWriterStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getWriterManagerStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getCounsellorStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getInterviewTrainerStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getCounsellingManagerStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getVisaCounsellingManagerStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getInterviewTrainerManagerStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getWriterStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getWriterManagerStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getCounsellorStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getCounsellorAllQualityCheckCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/type/qc_check`;
  }

  getInterviewTrainerStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getCounsellingManagerStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getVisaCounsellingManagerStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getInterviewTrainerManagerStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }
  getManagerCounsellorStudentsUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors/${counsellorId}/students`;
  }
  getManagerVisaCounsellorStudentsUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellors/${counsellorId}/students`;
  }
  getManagerInterviewTrainerStudentsUrl(userId, interviewTrainerId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_trainers/${interviewTrainerId}/students`;
  }
  getCounsellingManagerCounsellorStudentsUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors/${counsellorId}/students`;
  }
  getVisaCounsellingManagerVisaCounsellorStudentsUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/visa_counsellors/${counsellorId}/students`;
  }
  getInterviewTrainerManagerInterviewTrainerStudentsUrl(
    userId,
    interviewTrainerId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/interview_trainers/${interviewTrainerId}/students`;
  }
  getManagerCounsellorUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors/${counsellorId}`;
  }
  getManagerVisaCounsellorUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellors/${counsellorId}`;
  }
  getManagerInterviewTrainerUrl(userId, interviewTrainerId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_trainers/${interviewTrainerId}`;
  }
  getCounsellingManagerCounsellorDetailsUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors/${counsellorId}`;
  }
  getVisaCounsellingManagerVisaCounsellorUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/visa_counsellors/${counsellorId}`;
  }
  getInterviewTrainerManagerInterviewTrainerDetailsUrl(
    userId,
    interviewTrainerId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/interview_trainers/${interviewTrainerId}`;
  }
  getManagerCounsellorCollegesUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors/${counsellorId}/colleges`;
  }
  getCounsellingManagerCounsellorCollegesUrl(userId, counsellorId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors/${counsellorId}/colleges`;
  }

  getManagerCounsellingManagerUrl(userId, counsellingManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellor_managers/${counsellingManagerId}`;
  }
  getManagerVisaCounsellingManagerUrl(userId, visaCounsellingManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellor_managers/${visaCounsellingManagerId}`;
  }
  getManagerInterviewTrainerManagerUrl(userId, interviewTrainerManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/interview_managers/${interviewTrainerManagerId}`;
  }
  getCounsellingManagerCounsellorsUrl(userId, counsellingManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellor_managers/${counsellingManagerId}/counsellors`;
  }
  getVisaCounsellingManagerVisaCounsellorsUrl(userId, counsellingManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellor_managers/${counsellingManagerId}/visa_counsellors`;
  }
  getInterviewTrainerManagerInterviewTrainersUrl(
    userId,
    interviewTrainerManagerId
  ) {
    return `${this.getBaseUrl}/admins/${userId}/interview_managers/${interviewTrainerManagerId}/interview_trainers`;
  }
  getManagerWriterUrl(userId, writerId) {
    return `${this.getBaseUrl}/admins/${userId}/writers/${writerId}`;
  }
  getWriterManagerWriterUrl(userId, writerId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/writers/${writerId}`;
  }
  getManagerWriterStudentsUrl(userId, writerId) {
    return `${this.getBaseUrl}/admins/${userId}/writers/${writerId}/students`;
  }
  getWriterManagerWriterStudentsUrl(userId, writerId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/writers/${writerId}/students`;
  }
  getManagerWriterDocumentsUrl(userId, writerId) {
    return `${this.getBaseUrl}/admins/${userId}/writers/${writerId}/documents`;
  }
  getWriterDocumentsUrl(writerId) {
    return `${this.getBaseUrl}/writers/${writerId}/documents`;
  }
  getWriterStudentDocumentsUrl(writerId, studentId) {
    return `${this.getBaseUrl}/writers/${writerId}/students/${studentId}/documents`;
  }
  getWriterManagerWriterDocumentsUrl(userId, writerId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/writers/${writerId}/documents`;
  }
  getManagerWriterManagerUrl(userId, writerManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/writer_managers/${writerManagerId}`;
  }
  getManagerWriterManagerWritersUrl(userId, writerManagerId) {
    return `${this.getBaseUrl}/admins/${userId}/writer_managers/${writerManagerId}/writers`;
  }
  getAllManagerUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/managers`;
  }
  getManagerUrl(userId, managerId) {
    return `${this.getBaseUrl}/admins/${userId}/managers/${managerId}`;
  }
  getCounsellorEditProfileUrl(userId) {
    return `${this.getBaseUrl}/counsellors/${userId}`;
  }
  getVisaCounsellorEditProfileUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}`;
  }
  getWriterEditProfileUrl(userId) {
    return `${this.getBaseUrl}/writers/${userId}`;
  }
  getWriterManagerEditProfileUrl(userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}`;
  }
  getCounsellingManagerEditProfileUrl(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}`;
  }
  getVisaCounsellingManagerEditProfileUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}`;
  }
  getInterviewTrainerManagerEditProfileUrl(userId) {
    return `${this.getBaseUrl}/interview_managers/${userId}`;
  }
  getInterviewTrainerEditProfileUrl(userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}`;
  }
  getUserProgrammesUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/college_programs`;
  }

  getUnassignedUserProgrammesUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/college_programs/unassign`;
  }

  getCounsellingManagerProgrammesUrl(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs`;
  }
  getCollegeStudentsUrl(userId, collegeId) {
    return `${this.getBaseUrl}/admins/${userId}/college_programs/${collegeId}/students`;
  }
  getProgramUrl(userId, collegeId) {
    return `${this.getBaseUrl}/admins/${userId}/college_programs/${collegeId}?includes=college_program_documents,college_program_checklists`;
  }
  getCounsellingManagerCounsellorProgramUrl(userId, collegeId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs/${collegeId}?includes=college_program_documents,college_program_checklists`;
  }

  getCounsellorProgramUrl(userId, collegeId) {
    return `${this.getBaseUrl}/counsellors/${userId}/college_programs/${collegeId}?includes=college_program_documents,college_program_checklists`;
  }
  
  getCounsellingManagerCounsellorProgramIndividualUrl(userId, collegeId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs/${collegeId}`;
  }

  getAddProgamUrl() {
    return `${this.getBaseUrl}/programmes`;
  }
  getProgramByCategoryUrl(categoryId) {
    return `${this.getBaseUrl}/programmes-category/${categoryId}/programmes`;
  }
  getProgramCategoriesUrl() {
    return `${this.getBaseUrl}/programmes-category`;
  }
  getChatsUrl(id) {
    return `${this.getBaseUrl}/users/${id}/conversations`;
  }
  getExportChatsUrl(id, groupId) {
    return `${this.getBaseUrl}/users/${id}/conversations/${groupId}/download`;
  }
  downloadDocument(docName) {
    return `${this.baseUrl}/conversations/files/${docName}`;
  }
  getUserUrl(userId) {
    return `${this.getBaseUrl}/users/${userId}`;
  }
  getNotificationsUrl(id) {
    return `${this.getBaseUrl}/users/${id}/notifications`;
  }

  // ========================================================
  // Admin
  // ========================================================

  // Packages
  get getAllPackagesUrl() {
    return `${this.getBaseUrl}/packages`;
  }

  // Students
  getAllStudentsForAdminUrl(adminUserId) {
    return `${this.getBaseUrl}/admins/${adminUserId}/students`;
  }

  getSendPasswordLink(adminUserId: string, studentId: string) {
    return `${this.getBaseUrl}/admins/${adminUserId}/students/${studentId}/email`;
  }
  getSendPasswordLinkCounsellorManager(managerUserId: string, studentId: string) {
    return `${this.getBaseUrl}/counsellor_managers/${managerUserId}/students/${studentId}/email`;
  }

  getAllStudentsForWriterUrl(userId) {
    return `${this.getBaseUrl}/writers/${userId}/students`;
  }

  getAllStudentsForCounsellingManagerUrl(userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students`;
  }

  getAllStudentsForVisaCounsellingManagerUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students`;
  }

  getAllStudentsForInterviewTrainerManagerUrl(userId) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students`;
  }

  getAllStudentsForWriterManagerUrl(userId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students`;
  }

  getAllStudentsForCounsellorUrl(userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students`;
  }

  getAssignWriterCounsellorWriter(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/assign-writer-req`;
  }

  getAllStudentsForVisaCounsellorUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students`;
  }

  getAllSessionsForInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/interview_sessions`;
  }

  getAllSessionsForVisaCounsellorUrl(userId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/visa_sessions`;
  }

  getAllStudentsForInterviewTrainerUrl(userId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students`;
  }

  getAddStudentMegaUploadUrl(adminUserId) {
    return `${this.getBaseUrl}/admins/${adminUserId}/students`;
  }

  getStudentNotificationsUrl(adminId, studentId) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/notifications`;
  }

  getCounsellorStudentNotificationsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/notifications`;
  }

  getInterviewTrainerStudentNotificationsUrl(userId, studentId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}/notifications`;
  }

  getCounsellorStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}`;
  }

  removeEnrollmentFormHighlightsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/remove-highlights`;
  }

  getVisaCounsellorStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/visa_counsellors/${userId}/students/${studentId}`;
  }

  getInterviewTrainerStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/interview_trainers/${userId}/students/${studentId}`;
  }

  getCounsellingManagerStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}`;
  }

  getVisaCounsellingManagerStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}`;
  }

  getInterviewTrainerManagerStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}`;
  }

  getWriterManagerStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/writer_managers/${userId}/students/${studentId}`;
  }

  getWriterStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}`;
  }

  getCounsellorStudentLogsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/logs`;
  }

  getCounsellingManagerStudentLogsUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/logs`;
  }

  getUpdateCounsellorStudentHistoryUrl(userId, studentId, actionId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/logs/${actionId}`;
  }

  getUpdateWriterStudentHistoryUrl(userId, studentId, actionId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/logs/${actionId}`;
  }

  getAssociateStudentCounsellorForCounsellingManagerUrl(
    userId,
    studentId,
    counsellorId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors/${counsellorId}/students/${studentId}/associate`;
  }
  getCounsellorStudentRoadmapUrl(counsellorId, studentId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/roadmap`;
  }
  getAdmintudentRoadmapUrl(adminId, studentId) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/roadmap`;
  }

  // put student enrollment data
  putEnrollmentDetailsAdmin(id, studentName) {
    return `${this.getBaseUrl}/admins/${id}/students/${studentName}/enrolment`;
  }
  // Visa Counselling Manager
  getAllVisaCounsellingManagersUrl(userId, page = null) {
    return `${this.getBaseUrl}/admins/${userId}/visa_counsellor_managers`;
  }

  associateStudentVisaCounsellorForVisaCounsellorManagerUrl(
    userId,
    studentId,
    visaId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${userId}/students/${studentId}/visas/${visaId}/associate`;
  }

  // Counsellor Managers
  getAllCounsellingManagersUrl(userId, page = null) {
    return `${this.getBaseUrl}/admins/${userId}/counsellor_managers`;
  }

  getAssociateStudentCounsellorForAdminUrl(userId, studentId, counsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors/${counsellorId}/students/${studentId}/associate`;
  }

  getAssociateStudentInterviewTrainerForAdminUrl(
    userId,
    studentId,
    interviewTrainerId
  ) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/interviews/${interviewTrainerId}/associate`;
  }

  associateStudentVisaCounsellorForAdmin(userId, studentId, visaCounsellorId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}/visas/${visaCounsellorId}/associate`;
  }

  // Interview Trainer Managers
  getAllInterviewTrainerManagersUrl(userId, page = null) {
    return `${this.getBaseUrl}/admins/${userId}/interview_managers`;
  }

  getAssociateStudentInterviewTrainerForInterviewTrainerManagerUrl(
    userId,
    studentId,
    interviewTrainerId
  ) {
    return `${this.getBaseUrl}/interview_managers/${userId}/students/${studentId}/interviews/${interviewTrainerId}/associate`;
  }

  // Locations
  getLocationsUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/locations`;
  }
  getCounsellorLocationsUrl(counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/locations`;
  }

  getCounsellorProgramsUrl(counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/programs`
  }

  getCounsellorProgramCategoriesUrl(counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/program-categories`
  }
 
  getCounsellorManagerLocationsUrl(counsellingManagerId) {
    return `${this.getBaseUrl}/counsellor_managers/${counsellingManagerId}/locations`
  }

  // Colleges
  getCollegesUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/colleges`;
  }

  getAddCollegeUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/colleges`;
  }

  getAddLocationUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/locations`;
  }

  getUpdateCollegeUrl(collegeId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/colleges/${collegeId}`;
  }

  getCollegeByIdUrl(collegeId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/colleges/${collegeId}`;
  }

  getLocationByIdUrl(locationId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/locations/${locationId}`;
  }

  getExportCollegeUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/college/export`;
  }

  // Programs
  getAllProgramsUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/programs`;
  }

  getAddProgramUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/programs`;
  }

  getUpdateProgramUrl(programId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/programs/${programId}`;
  }

  getProgramByIdUrl(programId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/programs/${programId}`;
  }
  getExportProgramUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/program/export`;
  }

  getAllCollegeProgramForReviewByCounsellor(counsellingManagerId,counsellorId){
    return `${this.getBaseUrl}/counsellor_managers/${counsellingManagerId}/counsellors/individual-counsellor/${counsellorId}/review_college_programs`
   }

  // Program Categories
  getAllProgramCategoriesUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/program_categories`;
  }

  getAddProgramCategoriesUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/program_categories`;
  }

  getProgramCategoryByIdUrl(programCategoryId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/program_categories/${programCategoryId}`;
  }
  getUpdateProgramCategoryByIdUrl(programCategoryId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/program_categories/${programCategoryId}`;
  }

  // College Program

  getAddCollegeProgramUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs`;
  }

  getCollegeProgramUrl(collegeProgramId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs/${collegeProgramId}`;
  }

  getAddCollegeProgramDocumentUrl(collegeProgramId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs/${collegeProgramId}/documents`;
  }

  getAddCollegeProgramChecklistUrl(collegeProgramId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs/${collegeProgramId}/checklists`;
  }

  getCollegeProgramDocumentUrl(collegeProgramId, documentId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs/${collegeProgramId}/documents/${documentId}`;
  }

  getDeleteCollegeProgramTaskUrl(collegeProgramId, taskId, adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college_programs/${collegeProgramId}/checklists/${taskId}`;
  }



  getRemoveStudentSelfShortlistedCollegeProgramUrl(
    adminId,
    studentId,
    collegeProgramId
  ) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/programs/my/${collegeProgramId}`;
  }

  getCounsellorCollegeProgrammesUrl(userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/college_programs`;
  }
  getCounsellorCollegeProgrammesForStudentUrl(userId, studentId) {
    // return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/shortlist/college_programs`;
    return `${this.getBaseUrl}/counsellors/${userId}/all_college_programs`;
  }
  getCounsellorManagerCollegePrograms(userId) {
    // return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/shortlist/college_programs`;
    console.log(
      "=============+<>",
      `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs`
    );
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs`;
  }
  getShortListCollegesPostByCounsellorManagerUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/shortlisted`;
  }
  getShortListCollegesForStudentUrl(userId, studentId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/shortlist/college_programs`;
  }
  getCounsellorCollegeProgramUrl(collegeProgramId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/college_programs/${collegeProgramId}`;
  }

  updateUpcomingChangesColumnUrl(collegeProgramId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/college_programs/${collegeProgramId}`;
  }

  ApproveUpcomingChangesColumnUrl(collegeProgramId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs/${collegeProgramId}`
  }

  UnapproveUpcomingChangesUrl(collegeProgramId, userId) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/college_programs/${collegeProgramId}`
  }
  
  getUpdateShortlistedCollegeProgramUrl(userId, studentId, collegeProgramId) {
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${collegeProgramId}`;
  }
  getDeassignCounsellingManagerCounsellorCollegeProgramUrl(
    userId,
    counsellorId,
    collegeProgramId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${userId}/counsellors/${counsellorId}/colleges/${collegeProgramId}`;
  }
  getDeassignManagerCounsellorCollegeProgramUrl(
    userId,
    counsellorId,
    collegeProgramId
  ) {
    return `${this.getBaseUrl}/admins/${userId}/counsellors/${counsellorId}/colleges/${collegeProgramId}`;
  }
  getMarkUnmarkUrl(userId, studentId, collegeId, documentId, draftId) {
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${collegeId}/documents/${documentId}/drafts/${draftId}/unmark`;
  }
  getCopyDraftUrl(writerId, studentId, collegeId, documentID) {
    return `${this.getBaseUrl}/writers/${writerId}/students/${studentId}/programs/${collegeId}/documents/${documentID}/clone`;
  }
  getExportCollegeProgramUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/college-programs/export`;
  }
  getStudentShortlistedCollegeProgramInterviewSessionsUrl(
    adminId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getStudentShortlistedCollegeProgramVisaSessionsUrl(
    adminId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/admins/${adminId}/students/${studentId}/visas/${interviewId}/visa_sessions`;
  }
  getStudentShortlistedCollegeProgramInterviewSessionsForCounsellorUrl(
    counsellorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getStudentShortlistedCollegeProgramInterviewSessionsForCounsellorManagerUrl(
    counsellorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${counsellorId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getStudentShortlistedCollegeProgramVisaSessionsForCounsellorUrl(
    counsellorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/visas/${interviewId}/visa_sessions`;
  }
  getStudentShortlistedCollegeProgramVisaSessionsForCounsellorManagerUrl(
    counsellorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/counsellor_managers/${counsellorId}/students/${studentId}/visas/${interviewId}/visa_sessions`;
  }
  getInterviewTrainerStudentShortlistedCollegeProgramInterviewSessionsUrl(
    interviewTrainerManagerId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/interview_managers/${interviewTrainerManagerId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getVisaCounsellorStudentShortlistedCollegeProgramVisaSessionsUrl(
    visaCounsellorManagerId,
    studentId,
    visaId
  ) {
    return `${this.getBaseUrl}/visa_counsellor_managers/${visaCounsellorManagerId}/students/${studentId}/visas/${visaId}/visa_sessions`;
  }
  getVisaCounsellorStudentVisaSessionsUrl(visaCounsellorId, studentId, visaId) {
    return `${this.getBaseUrl}/visa_counsellors/${visaCounsellorId}/students/${studentId}/visas/${visaId}/visa_sessions`;
  }

  //=============================================================
  // Config
  //=============================================================

  getConfigDataUrl() {
    return `${this.getBaseUrl}/config`;
  }

  //=============================================================
  // Bulk Import
  //=============================================================

  getAddBulkStudentUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/students/import`;
  }
  getAddBulkStudentUrlCounsellorManagers(adminId) {
    return `${this.getBaseUrl}/counsellor_managers/${adminId}/students/import`;
  }

  getAddBulkCollegeUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college/import`;
  }
  getAddBulkProgramUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/program/import`;
  }
  getAddBulkCollegeProgramUrl(adminId) {
    return `${this.getBaseUrl}/admins/${adminId}/college-programs/import`;
  }

  //==============================================================
  // Counsellor
  //==============================================================

  getCounsellorLogsUrl(counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/logs`;
  }

  getUpdateCounsellorStudentCollegeResult(counsellorId, studentId, collegeId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/programs/${collegeId}`;
  }

  getUpdateCounsellorStudentCollege(counsellorId, studentId, collegeId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/programs/${collegeId}`;
  }

  getAllCollegePrograms(counsellorId){
    return `${this.getBaseUrl}/counsellors/${counsellorId}/all_college_programs`
  }

  getManagerCounsellorLogsUrl(adminId, counsellorId) {
    return `${this.getBaseUrl}/admins/${adminId}/counsellors/${counsellorId}/breach`;
  }

  // put data of studentenrollment
  putEnrollmentDetailsCounsellor(id, studentName) {
    return `${this.getBaseUrl}/counsellors/${id}/students/${studentName}/enrolment`;
  }

  //==============================================================
  // Writer
  //==============================================================

  getWriterLogsUrl(writerId) {
    return `${this.getBaseUrl}/writers/${writerId}/logs`;
  }

  getManagerWriterLogsUrl(adminId, writerId) {
    return `${this.getBaseUrl}/admins/${adminId}/writers/${writerId}/breach`;
  }

  //==============================================================
  // Course Types
  //==============================================================

  getCourseTypesUrl() {
    return `${this.getBaseUrl}/course_types`;
  }

  //==============================================================
  // Broadcast Message
  //==============================================================

  getBroadcastMessageUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/broadcast`;
  }

  //===============================================================
  // Mentor
  // ==============================================================
  getAddMentor(userId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors`;
  }

  getAllMentorsUrl(userId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/`;
  }

  getMentorProfileDetails(userId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}`;
  }

  getMentorStudents(userId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}/students`;
  }

  getSingleMentorDetails(userId, mentorName) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorName}`;
  }

  getStudentMentorUrl(userId, studentId) {
    return `${this.getBaseUrl}/admins/${userId}/students/${studentId}?includes=mentors`;
  }

  getAssociateStudentMentorForAdminUrl(userId, studentId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}/students/${studentId}/assign`;
  }
  getManagerMentorUrl(userId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}`;
  }

  // Deassign admin college programs

  getDeassignManagerMentorCollegeProgramUrl(
    userId,
    mentorId,
    collegeProgramId
  ) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}/colleges/${collegeProgramId}`;
  }
  // mentor college details
  getManagerMentorCollegesUrl(userId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}/colleges`;
  }

  getManagerMentorCollegeUrl(userId, mentorId) {
    return `${this.getBaseUrl}/admins/${userId}/mentors/${mentorId}/colleges`;
  }

  //========================================================================================
  // Mentor side portal api's
  //=======================================================================================
  getAllMentorStudents(userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students`;
  }

  getMentorIndividualStudentDetail(userId, studentSlug) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentSlug}`;
  }

  getMentorStudentRoadmap(mentorSlug, studentId) {
    return `${this.getBaseUrl}/mentors/${mentorSlug}/students/${studentId}/roadmap`;
  }

  getAddMentorStudentRoadmap(mentorSlug, studentId) {
    return `${this.getBaseUrl}/mentors/${mentorSlug}/students/${studentId}/roadmap`;
  }

  getMentorNotification(userId, studentSlug) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentSlug}/notifications`;
  }

  getMentorLogUrl(mentorId) {
    return `${this.getBaseUrl}/mentors/${mentorId}/logs`;
  }
  getUpdateMentorStudentHistoryUrl(userId, studentId, actionId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/logs/${actionId}`;
  }

  getMentorEditProfileUrl(userId) {
    return `${this.getBaseUrl}/mentors/${userId}`;
  }
  getMentorStudentDetailsUrl(userId, studentId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}`;
  }
  getMentorStudentAllShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/type/shortlisted`;
  }
  getMentorStudentAllSelfShortlistedCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/type/my`;
  }
  getMentorAllQualityCheckCollegesUrl(studentId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/type/qc_check`;
  }

  /////////////////////////////////////
  getMentorCollegeProgrammesForStudentUrl(userId, studentId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/shortlist/college_programs`;
  }

  getMentorStudentProgramDetailsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/${programId}`;
  }

  getMentorStudentProgramDocumentsUrl(studentId, programId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/${programId}/documents?includes=college_program_document,document_file_type,writer`;
  }
  getMentorStudentProgramDocumentDetailsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}?includes=writer,college_program_document`;
  }

  getMentorStudentProgramDocumentDraftsUrl(
    studentId,
    programId,
    userId,
    documentId
  ) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts`;
  }
  getUpdateMentorStudentCollege(mentorId, studentId, collegeId) {
    return `${this.getBaseUrl}/mentors/${mentorId}/students/${studentId}/programs/${collegeId}`;
  }

  getStudentShortlistedCollegeProgramInterviewSessionsForMentorUrl(
    mentorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/mentors/${mentorId}/students/${studentId}/interviews/${interviewId}/interview_sessions`;
  }
  getStudentShortlistedCollegeProgramVisaSessionsForMentorUrl(
    mentorId,
    studentId,
    interviewId
  ) {
    return `${this.getBaseUrl}/mentors/${mentorId}/students/${studentId}/visas/${interviewId}/visa_sessions`;
  }
  getUpdateShortlistedCollegeProgramByMentorUrl(
    userId,
    studentId,
    collegeProgramId
  ) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/programs/${collegeProgramId}`;
  }
  getShortListCollegesForStudentByMentorUrl(userId, studentId) {
    return `${this.getBaseUrl}/mentors/${userId}/students/${studentId}/shortlist/college_programs`;
  }
  getUpdateMentorStudentCollegeResult(counsellorId, studentId, collegeId) {
    return `${this.getBaseUrl}/mentors/${counsellorId}/students/${studentId}/programs/${collegeId}`;
  }

  // Edit Roadmap counsellor and mentor api's

  getUpdateRoadmapCounsellorUrl(counsellorId, studentId, taskId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/${studentId}/roadmap/${taskId}`;
  }
  getDeleteRoadmapCounsellorUrl(counsellorId, studentId, taskId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/roadmap/${taskId}`;
  }
  getUpdateRoadmapMentorUrl(mentorId, studentId, taskId) {
    return `${this.getBaseUrl}/mentors/${mentorId}/${studentId}/roadmap/${taskId}`;
  }
  getDeleteRoadmapMentorUrl(mentorId, studentId, taskId) {
    return `${this.getBaseUrl}/mentors/${mentorId}/students/${studentId}/roadmap/${taskId}`;
  }

  deleteTaskUrl(counsellorId, studentId, taskId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/students/${studentId}/activity/${taskId}`
  }

  //=============================================================
  // roadmap tags
  //============================================================
  getRoadmapTagsUrl() {
    return `${this.getBaseUrl}/tags`;
  }

  //===========================================================
  // Activity Apis Mentor
  //===========================================================
  getAddActivityMentorUrl(mentorslug, studentId, tagId) {
    return `${this.getBaseUrl}/mentors/${mentorslug}/students/${studentId}/activity/${tagId}`;
  }
  getUpdateActivityMentorUrl(mentorslug, studentId, activityId) {
    return `${this.getBaseUrl}/mentors/${mentorslug}/students/${studentId}/activity/${activityId}`;
  }
  getActivityMentorUrl(mentorslug, studentId) {
    return `${this.getBaseUrl}/mentors/${mentorslug}/students/${studentId}/activity`;
  }

  //=============================================================
  // Activity Apis Counsellor
  //=============================================================
  getAddActivityCounsellorUrl(counsellorslug, studentId, tagId) {
    return `${this.getBaseUrl}/counsellors/${counsellorslug}/students/${studentId}/activity/${tagId}`;
  }
  getUpdateActivityCounsellorUrl(counsellorslug, studentId, activityId) {
    return `${this.getBaseUrl}/counsellors/${counsellorslug}/students/${studentId}/activity/${activityId}`;
  }
  getActivityCounsellorUrl(counsellorslug, studentId) {
    return `${this.getBaseUrl}/counsellors/${counsellorslug}/students/${studentId}/activity`;
  }

  //=============================================================
  // Activity Api Admin
  //=============================================================
  getActivityAdminUrl(adminslug, studentslug) {
    return `${this.getBaseUrl}/admins/${adminslug}/students/${studentslug}/activity`;
  }

  //=============================================================
  // API routes for appointments
  //=============================================================

  getCreateAppointmentByMentorUrl(mentorId) {
    return `${this.getBaseUrl}/mentors/${mentorId}/appointments`;
  }

  getCreateAppointmentByCounsellorUrl(counsellorId) {
    return `${this.getBaseUrl}/counsellors/${counsellorId}/appointments`;
  }

  getAllAppointmentUrl() {
    return `${this.getBaseUrl}/appointments`;
  }

  getConfirmAppointmentMentorSlot(appointmentId, userId) {
    return `${this.getBaseUrl}/mentors/${userId}/appointments/${appointmentId}`;
  }

  setDefaultCollegeUrl(userId){
    return `${this.getBaseUrl}/admins/${userId}/update/default-college-programs`
  }

  getConfirmAppointmentCounsellorSlot(appointmentId, userId) {
    return `${this.getBaseUrl}/counsellors/${userId}/appointments/${appointmentId}`;
  }
  // Counsellor Manager Shortlisted College Program Routes
  getShortlistedCollegeProgramByCounsellingManager(
    userId,
    studentId,
    programId
  ) {
    console.log("url started ");
    return `${this.getBaseUrl}/counsellor_managers/${userId}/students/${studentId}/programs/shortlisted/${programId}`;
  }

  getShortlistedCollegeProgramByWriter(
    userId,
    studentId,
    programId,
    documentId,
    draftId
  ) {
    console.log("url started ");
    return `${this.getBaseUrl}/writers/${userId}/students/${studentId}/programs/${programId}/documents/${documentId}/drafts/${draftId}`;
  }

  getShortlistedCollegeProgramByCounsellor(userId, studentId, programId) {
    console.log("url started ");
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}`;
  }

  getShortlistedCollegeStepUpdateUrl(userId, studentId, programId) {
    console.log("url started ");
    return `${this.getBaseUrl}/counsellors/${userId}/students/${studentId}/programs/${programId}/college_step`;
  }

  updateUserRolesTable() {
    return `${this.getBaseUrl}/admins/update-user-role`
  }

  updateRoadmapMeta(){
    return `${this.getBaseUrl}/admins/update-roadmap-meta`
  }

  getUserRoles(userId){
    return `${this.getBaseUrl}/users/${userId}/get-role`
  }
}
