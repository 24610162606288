import { Component, TemplateRef, OnInit } from "@angular/core";
// import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { Toastr } from "../../../plugins/toastr/toastr";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { CustomValidator } from "../../../utilities/validator/custom-validator";
import * as moment from "moment";
import { StudentService } from "src/app/core/services/student.service";
import { Helper } from "src/app/core/utilities/helpers";
import { Observable } from "rxjs";
import { ProgramService } from "src/app/core/services/program.service";
import { UserService } from "src/app/core/services/user.services";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { CollegeProgramService } from "src/app/core/services/college.program.service";

@Component({
  selector: "copy-draft-modal",
  templateUrl: "./copy-draft.component.html",
  providers: [StudentService, Toastr, ProgramService, CollegeProgramService],
})
export class CopyDraftModal implements OnInit {
  public documents: any = [];
  public writerId: any;
  public paginationData: any;
  public total: number;
  public page: number = 0;
  public per_page: number = 0;
  public loading: boolean = false;
  public searchInputValue: any = "";
  public sortByParams: Object = {
    document_name: false,
    student_name: false,
    college_name: false,
    program_name: false,
    status: false,
  };
  public sort_by: any = {
    column: "",
    type: "",
  };
  studentId: any;
  documentId: any;
  collegeId: any;
  public studentOwnerId: any;
  public selectedDocument: any = [];

  constructor(
    public modalRef: BsModalRef,
    public userService: UserService,
    public authService: AuthenticationService,
    public toastr: Toastr,
    public collegeProgramService: CollegeProgramService,
    public studentService: StudentService
  ) {}

  ngOnInit() {
    this.getStudentDetails();
  }

  _onSortByClick(column_name: string) {
    this._toggleSortByParams(column_name);
    this.getDocuments();
  }

  _toggleSortByParams(key: string = "") {
    for (var property in this.sortByParams) {
      if (this.sortByParams.hasOwnProperty(property)) {
        if (property == key) {
          this.sortByParams[key] =
            this.sortByParams[key] == false
              ? "desc"
              : this.sortByParams[key] == "desc"
              ? "asc"
              : "desc";
          this.sort_by = {
            column: key,
            type: this.sortByParams[key],
          };
        } else {
          this.sortByParams[property] = false;
        }
      }
    }
  }

  getStudentDetails() {
    this._startLoading();
    this.studentService.getWriterStudentDetails(this.studentId).subscribe(
      (res) => {
        this.studentOwnerId = res.owner.id;
        this.getDocuments();
      },
      (error) => {
        this._stopLoading();
      }
    );
  }

  getDocuments(page = 1, params = {}) {
    this._startLoading();
    this.documents = [];
    let sort_by = "";
    for (let property in this.sortByParams) {
      if (this.sortByParams[property]) {
        sort_by = property + "," + this.sortByParams[property];
        break;
      }
    }
    params = Helper.cloneObj({
      page: page,
      query: this.searchInputValue,
      sort_by: sort_by,
      includes:
        "writer,college_program_document,shortlisted_student_program,student,college,college_program",
    });
    this.userService
      .getWriterStudentDocuments(this.studentId, params)
      .subscribe(
        (res) => {
          this.documents = [];
          let index = 0;
          this.paginationData = res.meta.pagination;
          this.total = this.paginationData.total;
          this.per_page = this.paginationData.per_page;
          this.page = this.paginationData.current_page;
          res.data.map((element) => {
            this.documents.push(
              Helper.cloneObj(element, this._getOtherDetails(element))
            );
          });

          this._stopLoading();
        },
        (error) => {
          this._stopLoading();
          console.log(error);
        }
      );
  }

  check(e) {
    if (e.target.checked) {
      this.selectedDocument.push(e.target.value);
    } else {
      let tempArray = this.selectedDocument;
      this.selectedDocument = [];
      tempArray.forEach((element) => {
        if (e.target.value == element) {
          // Do Nothing
        } else {
          this.selectedDocument.push(element);
        }
      });
    }
    console.log("Selected Documents::::::::", this.selectedDocument);
  }

  _getOtherDetails(element) {
    let tempObject = {
      statusClass: this.getStatusClass(element.status),
    };
    return tempObject;
  }

  getStatusClass(element) {
    switch (element) {
      case 0:
        return "warning";

      case 1:
        return "info";

      case 2:
        return "success";

      default:
        return "info";
    }
  }

  submit() {
    if (this.selectedDocument.length == 0) {
      this.toastr.showWarning(
        "In order to copy final draft, you need to select atleast one document."
      );
      return;
    }
    this._startLoading();
    let formData = {
      target_documents: this.selectedDocument,
    };
    this.collegeProgramService
      .copyDraft(formData, this.studentOwnerId, this.collegeId, this.documentId)
      .subscribe(
        (res) => {
          this._stopLoading();
          this.modalRef.hide();
          this.toastr.showSuccess(
            "Successfully copied draft to the selected document of student."
          );
        },
        (error) => {
          this._stopLoading();
        }
      );
  }

  _startLoading() {
    this.loading = true;
  }

  _stopLoading() {
    this.loading = false;
  }
}
