import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiRoutes } from "../config/api-routes";
import "rxjs/Rx";
import { Router, ActivatedRoute } from "@angular/router";
import { Storage } from "../utilities/storage";
import { Helper } from "../utilities/helpers";

@Injectable()
export class UserService {
  public collegeDetails: any = null;

  constructor(
    private http: HttpClient,
    private apiRoutes: ApiRoutes,
    private router: Router,
    public storage: Storage
  ) {}

  get currentUser() {
    return this.storage.get("authUser");
  }

  get currentOwner() {
    let currentUser = this.storage.get("authUser");

    return Helper.getObjProp(currentUser, "owner");
  }
  getCurrentUser() {
    return this.storage.get("authUser");
  }

  setTempLogin(authUser) {
    this.storage.set("tempLogin", authUser);
  }
  setCredentials(authUser) {
    // const allRoles = this.storage.get('tempLogin');
    // let authUser = allRoles[selectedRole];
    // manipulate role
    this.storage.set("authUser", authUser);
    this.storage.remove('tempLogin');
    // this.storage.set('currentUser', {
    //     id: res.data.slug,
    //     username: res.data.full_name,
    //     token: res.data.token,
    //     email: res.data.email,
    //     phone: res.data.mobile_number,
    //     dob: res.data.dob_formatted,
    //     is_admin: res.data.is_admin,
    //     is_counsellor: res.data.is_counsellor,
    //     is_counsellor_manager: res.data.is_counsellor_manager,
    //     is_writer: res.data.is_writer,
    //     is_writer_manager: res.data.is_writer_manager
    // });
    // this.storage.set('currentOwner', { id: res.data.owner.id });
  }

  setConfig(configData) {
    this.storage.set("config", configData);
  }

  getConfigData() {
    return this.http.get(this.apiRoutes.getConfigDataUrl()).map((res: any) => {
      return res;
    });
  }


  readConversation(groupId) {
    return this.http
      .put(
        this.apiRoutes.getReadConversationUrl(this.currentUser.slug, groupId),
        {}
      )
      .map((res: any) => {
        return res;
      });
  }

  setRootPage(data, toastr: any) {
    if (data.is_admin) {
      this.router.navigate(["/manager"]);
    } else if (data.is_counsellor) {
      this.router.navigate(["/counsellor"]);
    } else if (data.is_counsellor_manager) {
      this.router.navigate(["/counselling-manager"]);
    } else if (data.is_writer) {
      this.router.navigate(["/writer"]);
    } else if (data.is_writer_manager) {
      this.router.navigate(["/writer-manager"]);
    } else if (data.is_student) {
      toastr.showWarning("You are not allowed to log in from this app");
    } else if (data.is_interview_manager) {
      this.router.navigate(["/interview-trainer-manager"]);
    } else if (data.is_interview_trainer) {
      this.router.navigate(["/interview-trainer"]);
    } else if (data.is_visa_counsellor_manager) {
      this.router.navigate(["/visa-counsellor-manager"]);
    } else if (data.is_visa_counsellor) {
      this.router.navigate(["/visa-counsellor"]);
    } else if (data.is_mentor) {
      this.router.navigate(["/mentor"]);
    }
  }

  getStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForAdminUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForWriterUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForCounsellingManagerUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForVisaCounsellingManagerUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForInterviewTrainerManagerUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  
  getWriterManagerStudentList(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForWriterManagerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }



  addStudent(form) {
    return this.http
      .post(this.apiRoutes.getAddStudentUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }
  addStudentCounsellorManager(form) {
    return this.http
      .post(
        this.apiRoutes.getAddStudentUrlCounsellorManager(this.currentUser.id),
        form
      )
      .map((res: any) => {
        return res;
      });
  }

  updateStudent(studentId, form) {
    return this.http
      .put(
        this.apiRoutes.getStudentDetailsUrl(this.currentUser.id, studentId),
        form
      )
      .map((res: any) => {
        return res;
      });
  }

  addCounsellor(form) {
    return this.http
      .post(this.apiRoutes.getAddCounsellorUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  // create a mentor
  addMentor(form) {
    return this.http
      .post(this.apiRoutes.getAddMentor(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  addVisaCounsellor(form) {
    return this.http
      .post(this.apiRoutes.getAddVisaCounsellorUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  addInterviewTrainer(form) {
    return this.http
      .post(this.apiRoutes.getAddInterviewTrainerUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  addCounsellingManager(form) {
    return this.http
      .post(
        this.apiRoutes.getAddCounsellingManagerUrl(this.currentUser.id),
        form
      )
      .map((res: any) => {
        return res;
      });
  }

  addVisaCounsellingManager(form) {
    return this.http
      .post(
        this.apiRoutes.getAddVisaCounsellingManagerUrl(this.currentUser.id),
        form
      )
      .map((res: any) => {
        return res;
      });
  }

  addInterviewTrainerManager(form) {
    return this.http
      .post(
        this.apiRoutes.getAddInterviewTrainerManagerUrl(this.currentUser.id),
        form
      )
      .map((res: any) => {
        return res;
      });
  }

  addWriter(form) {
    return this.http
      .post(this.apiRoutes.getAddWriterUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  addWriterManager(form) {
    return this.http
      .post(this.apiRoutes.getAddWriterManagerUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  addManager(form) {
    return this.http
      .post(this.apiRoutes.getAddManagerUrl(this.currentUser.id), form)
      .map((res: any) => {
        return res;
      });
  }

  getStudentHistory(studentId, params = {}) {
    let url =
      this.apiRoutes.getStudentLogsUrl(this.currentUser.id, studentId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterStudentHistory(studentId, params = {}) {
    let url =
      this.apiRoutes.getWriterStudentLogsUrl(this.currentUser.id, studentId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerStudentHistory(studentId, params = {}) {
    let url =
      this.apiRoutes.getWriterManagerStudentLogsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentHistory(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorStudentLogsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorLogs(params = {}) {
    let url =
      this.apiRoutes.getCounsellorLogsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorLogs(params = {}) {
    let url =
      this.apiRoutes.getMentorLogUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerCounsellorLogs(params = {}, counsellorId) {
    let url =
      this.apiRoutes.getManagerCounsellorLogsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterLogs(params = {}) {
    let url =
      this.apiRoutes.getWriterLogsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerWriterLogs(params = {}, writerId) {
    let url =
      this.apiRoutes.getManagerWriterLogsUrl(this.currentUser.id, writerId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerStudentHistory(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerStudentLogsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getStudentDetails(studentId) {
    return this.http
      .get(this.apiRoutes.getStudentDetailsUrl(this.currentUser.id, studentId))
      .map((res: any) => {
        return res;
      });
  }

  updateStudentStatus(studentId, status) {
    return this.http
      .put(
        this.apiRoutes.getStudentDetailsUrl(this.currentUser.id, studentId),
        { student_state: status }
      )
      .map((res: any) => {
        return res;
      });
  }

  getStudentCounsellingManager(studentId) {
    return this.http
      .get(
        this.apiRoutes.getStudentCounsellingManagerUrl(
          this.currentUser.id,
          studentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getAllCounsellingManagers(params = {}) {
    let url =
      this.apiRoutes.getAllCounsellingManagersUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllVisaCounsellingManagers(params = {}) {
    let url =
      this.apiRoutes.getAllVisaCounsellingManagersUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllInterviewTrainerManagers(params = {}) {
    let url =
      this.apiRoutes.getAllInterviewTrainerManagersUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  associateStudentCounsellingManager(studentId, counsellingManagerId) {
    return this.http
      .post(
        this.apiRoutes.getAssociateStudentCounsellingManagerUrl(
          studentId,
          counsellingManagerId
        ),
        {}
      )
      .map((res: any) => {
        return res;
      });
  }

  getStudentWriterManager(studentId) {
    return this.http
      .get(this.apiRoutes.getStudentWriterManagerUrl(studentId))
      .map((res: any) => {
        return res;
      });
  }

  getAllWriterManagers(params: any = {}) {
    let url =
      this.apiRoutes.getAllWriterManagerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  associateStudentWriterManager(studentId, writerManagerId) {
    return this.http
      .post(
        this.apiRoutes.getAssociateStudentWriterManagerUrl(
          studentId,
          writerManagerId
        ),
        {}
      )
      .map((res: any) => {
        return res;
      });
  }

  getStudentCounsellor(studentId) {
    return this.http
      .get(
        this.apiRoutes.getStudentCounsellorUrl(this.currentUser.id, studentId)
      )
      .map((res: any) => {
        return res;
      });
  }

  //Get detail of mentor particular student
  getStudentMentor(studentId) {
    return this.http
      .get(this.apiRoutes.getStudentMentorUrl(this.currentUser.id, studentId))
      .map((res: any) => {
        return res;
      });
  }

  getStudentInterviewTrainer(studentId) {
    return this.http
      .get(
        this.apiRoutes.getStudentInterviewTrainerUrl(
          this.currentUser.id,
          studentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getCounsellingManagerStudentCounsellor(studentId) {
    return this.http
      .get(
        this.apiRoutes.getCounsellingManagerStudentCounsellorUrl(
          this.currentUser.id,
          studentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getInterviewTrainerManagerStudentCounsellor(studentId) {
    return this.http
      .get(
        this.apiRoutes.getInterviewTrainerManagerStudentCounsellorUrl(
          this.currentUser.id,
          studentId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getAllCounsellors(params = {}) {
    let url =
      this.apiRoutes.getAllCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);

    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  // get all mentors
  getAllMentors(params = {}) {
    let url =
      this.apiRoutes.getAllMentorsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  // get student list of mentors
  getAllMentorStudents(params = {}) {
    let url =
      this.apiRoutes.getAllMentorStudents(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  // Mentor's Individual student details
  getMentorIndividualStudentDetail(studentSlug) {
    let url = this.apiRoutes.getMentorIndividualStudentDetail(
      this.currentUser.id,
      studentSlug
    );
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  getAllVisaCounsellors(params = {}) {
    let url =
      this.apiRoutes.getAllVisaCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);

    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllInterviewTrainers(params = {}) {
    let url =
      this.apiRoutes.getAllInterviewTrainerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllInterviewTrainerManagerInterviewTrainers(params = {}) {
    let url =
      this.apiRoutes.getAllInterviewTrainerManagerInterviewTrainerUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllVisaCounsellorManagerVisaCounsellors(params = {}) {
    let url =
      this.apiRoutes.getAllVisaCounsellorManagerVisaCounsellorsUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerCounsellors(params = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);

    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerInterviewTrainers(params = {}) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerInterviewTrainerUrl(
        this.currentUser.id
      ) + Helper.getQueryParamsString(params);

    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  associateStudentCounsellor(studentId, counsellorId) {
    return this.http
      .post(
        this.apiRoutes.getAssociateStudentCounsellorUrl(
          studentId,
          counsellorId
        ),
        {}
      )
      .map((res: any) => {
        return res;
      });
  }

  associateStudentCounsellorForAdmin(
    studentId,
    counsellorId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.getAssociateStudentCounsellorForAdminUrl(
        this.currentUser.id,
        studentId,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  associateStudentMentorForAdmin(studentId, mentorId, form, params = {}) {
    let url =
      this.apiRoutes.getAssociateStudentMentorForAdminUrl(
        this.currentUser.id,
        studentId,
        mentorId
      ) + Helper.getQueryParamsString(params);

    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  associateStudentInterviewTrainerForAdmin(
    studentId,
    interviewTrainerId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.getAssociateStudentInterviewTrainerForAdminUrl(
        this.currentUser.id,
        studentId,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  associateStudentVisaCounsellorForAdmin(
    studentId,
    interviewTrainerId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.associateStudentVisaCounsellorForAdmin(
        this.currentUser.id,
        studentId,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  sendPasswordLink(studentId: string) {
    let url = this.apiRoutes.getSendPasswordLink(
      this.currentUser.id,
      studentId
    );
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }
  sendPasswordLinkCounsellorManager(studentId: string) {
    let url = this.apiRoutes.getSendPasswordLinkCounsellorManager(
      this.currentUser.id,
      studentId
    );
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  associateStudentInterviewTrainerForInterviewTrainerManager(
    studentId,
    interviewTrainerId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.getAssociateStudentInterviewTrainerForInterviewTrainerManagerUrl(
        this.currentUser.id,
        studentId,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  associateStudentVisaCounsellorForVisaCounsellorManager(
    studentId,
    visaCounsellorId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.associateStudentVisaCounsellorForVisaCounsellorManagerUrl(
        this.currentUser.id,
        studentId,
        visaCounsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  associateStudentCounsellorForCounsellingManager(
    studentId,
    counsellorId,
    form,
    params = {}
  ) {
    let url =
      this.apiRoutes.getAssociateStudentCounsellorForCounsellingManagerUrl(
        this.currentUser.id,
        studentId,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.post(url, form).map((res: any) => {
      return res;
    });
  }

  getStudentWriter(studentId) {
    return this.http
      .get(this.apiRoutes.getStudentWriterUrl(studentId))
      .map((res: any) => {
        return res;
      });
  }

  getAllWriters(params) {
    let url =
      this.apiRoutes.getAllWritersUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerAllWriters(params) {
    let url =
      this.apiRoutes.getWriterManagerAllWritersUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  associateStudentDocumentWriter(studentId, writerId, formData) {
    return this.http
      .post(
        this.apiRoutes.getAssociateStudentDocumentWriterUrl(
          this.currentUser.id,
          studentId,
          writerId
        ),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  associateWriterManagerStudentDocumentWriter(studentId, writerId, formData) {
    return this.http
      .post(
        this.apiRoutes.getAssociateWriterManagerStudentDocumentWriterUrl(
          this.currentUser.id,
          studentId,
          writerId
        ),
        formData
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerCounsellorStudents(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getManagerCounsellorStudentsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerVisaCounsellorStudents(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getManagerVisaCounsellorStudentsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerInterviewTrainerStudents(interviewTrainerId, params = {}) {
    let url =
      this.apiRoutes.getManagerInterviewTrainerStudentsUrl(
        this.currentUser.id,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerCounsellorStudents(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerCounsellorStudentsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerVisaCounsellorStudents(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerVisaCounsellorStudentsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerInterviewTrainerStudents(
    interviewTrainerId,
    params = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerInterviewTrainerStudentsUrl(
        this.currentUser.id,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerCounsellor(counsellorId, params: any = {}) {
    let url =
      this.apiRoutes.getManagerCounsellorUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllCollegeProgramForReview(params: any = {}, counsellorId){
    let url = this.apiRoutes.getAllCollegeProgramForReviewByCounsellor(
      this.currentUser.id,
      counsellorId
    ) + Helper.getQueryParamsString(params);        
    return this.http.get(url).map((res: any) => {      
      return res;
    })
  }

  getManagerMentor(mentorId, params: any = {}) {
    let url =
      this.apiRoutes.getManagerMentorUrl(this.currentUser.id, mentorId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorProfileDetails(mentorId, params: any = {}) {
    let url =
      this.apiRoutes.getMentorProfileDetails(this.currentUser.id, mentorId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorStudents(mentorId, params: any = {}) {
    let url =
      this.apiRoutes.getMentorStudents(this.currentUser.id, mentorId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getSingleMentorDetails(mentorName) {
    let url = this.apiRoutes.getSingleMentorDetails(
      this.currentUser.id,
      mentorName
    );
    return this.http.get(url).map((res) => {
      return res;
    });
  }

  getManagerVisaCounsellor(counsellorId, params: any = {}) {
    let url =
      this.apiRoutes.getManagerVisaCounsellorUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerInterviewTrainer(interviewTrainerId, params: any = {}) {
    let url =
      this.apiRoutes.getManagerInterviewTrainerUrl(
        this.currentUser.id,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerCounsellor(counsellorId, params: any = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerCounsellorDetailsUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingManagerVisaCounsellor(counsellorId, params: any = {}) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerVisaCounsellorUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerManagerInterviewTrainer(
    interviewTrainerId,
    params: any = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerInterviewTrainerDetailsUrl(
        this.currentUser.id,
        interviewTrainerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerCounsellorColleges(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getManagerCounsellorCollegesUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerMentorColleges(mentorId, params = {}) {
    let url =
      this.apiRoutes.getManagerMentorCollegesUrl(
        this.currentUser.id,
        mentorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellingManagerCounsellorColleges(counsellorId, params = {}) {
    let url =
      this.apiRoutes.getCounsellingManagerCounsellorCollegesUrl(
        this.currentUser.id,
        counsellorId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }


  updateManagerCounsellor(counsellorId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerCounsellorUrl(
          this.currentUser.id,
          counsellorId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerMentor(mentorId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerMentorUrl(this.currentUser.id, mentorId),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerVisaCounsellor(counsellorId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerVisaCounsellorUrl(
          this.currentUser.id,
          counsellorId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerInterviewTrainer(interviewTrainerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerInterviewTrainerUrl(
          this.currentUser.id,
          interviewTrainerId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerCounsellingManager(counsellingManagerId) {
    return this.http
      .get(
        this.apiRoutes.getManagerCounsellingManagerUrl(
          this.currentUser.id,
          counsellingManagerId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerVisaCounsellingManager(visaCounsellingManagerId) {
    return this.http
      .get(
        this.apiRoutes.getManagerVisaCounsellingManagerUrl(
          this.currentUser.id,
          visaCounsellingManagerId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerInterviewTrainerManager(interviewTrainerManagerId) {
    return this.http
      .get(
        this.apiRoutes.getManagerInterviewTrainerManagerUrl(
          this.currentUser.id,
          interviewTrainerManagerId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerCounsellingManager(counsellingManagerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerCounsellingManagerUrl(
          this.currentUser.id,
          counsellingManagerId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerVisaCounsellingManager(visacounsellingManagerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerVisaCounsellingManagerUrl(
          this.currentUser.id,
          visacounsellingManagerId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerInterviewTrainerManager(interviewTrainerManagerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerInterviewTrainerManagerUrl(
          this.currentUser.id,
          interviewTrainerManagerId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerCounsellingManagerCounsellors(
    counsellingManagerId,
    params: any = {}
  ) {
    let url =
      this.apiRoutes.getCounsellingManagerCounsellorsUrl(
        this.currentUser.id,
        counsellingManagerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerVisaCounsellingManagerVisaCounsellors(
    counsellingManagerId,
    params: any = {}
  ) {
    let url =
      this.apiRoutes.getVisaCounsellingManagerVisaCounsellorsUrl(
        this.currentUser.id,
        counsellingManagerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerInterviewTrainerManagerInterviewTrainers(
    interviewTrainerManagerId,
    params: any = {}
  ) {
    let url =
      this.apiRoutes.getInterviewTrainerManagerInterviewTrainersUrl(
        this.currentUser.id,
        interviewTrainerManagerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerWriter(writerId, params: any = {}) {
    let url =
      this.apiRoutes.getManagerWriterUrl(this.currentUser.id, writerId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerWriter(writerId, params: any = {}) {
    let url =
      this.apiRoutes.getWriterManagerWriterUrl(this.currentUser.id, writerId) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  updateManagerWriter(writerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerWriterUrl(this.currentUser.id, writerId),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerWriterStudents(writerId, params = {}) {
    let url =
      this.apiRoutes.getManagerWriterStudentsUrl(
        this.currentUser.id,
        writerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerWriterStudents(writerId, params = {}) {
    let url =
      this.apiRoutes.getWriterManagerWriterStudentsUrl(
        this.currentUser.id,
        writerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerWriterDocuments(writerId, params) {
    let url =
      this.apiRoutes.getManagerWriterDocumentsUrl(
        this.currentUser.id,
        writerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterDocuments(params) {
    let url =
      this.apiRoutes.getWriterDocumentsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterStudentDocuments(studentId, params) {
    let url =
      this.apiRoutes.getWriterStudentDocumentsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getWriterManagerWriterDocuments(writerId, params) {
    let url =
      this.apiRoutes.getWriterManagerWriterDocumentsUrl(
        this.currentUser.id,
        writerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManagerWriterManager(writerManagerId) {
    return this.http
      .get(
        this.apiRoutes.getManagerWriterManagerUrl(
          this.currentUser.id,
          writerManagerId
        )
      )
      .map((res: any) => {
        return res;
      });
  }

  updateManagerWriterManager(writerId, data) {
    return this.http
      .put(
        this.apiRoutes.getManagerWriterManagerUrl(
          this.currentUser.id,
          writerId
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  getManagerWriterManagerWriters(writerId, params = {}) {
    let url =
      this.apiRoutes.getManagerWriterManagerWritersUrl(
        this.currentUser.id,
        writerId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAllManagers(params = {}) {
    let url =
      this.apiRoutes.getAllManagerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getManager(managerId) {
    return this.http
      .get(this.apiRoutes.getManagerUrl(this.currentUser.id, managerId))
      .map((res: any) => {
        return res;
      });
  }

  updateManager(managerId, data) {
    return this.http
      .put(this.apiRoutes.getManagerUrl(this.currentUser.id, managerId), data)
      .map((res: any) => {
        return res;
      });
  }

  counsellorEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getCounsellorEditProfileUrl(this.currentUser.id),
        data
      )
      .map((res: any) => {
        return res;
      });
  }
  mentorEditProfile(data) {
    return this.http
      .put(this.apiRoutes.getMentorEditProfileUrl(this.currentUser.id), data)
      .map((res: any) => {
        return res;
      });
  }

  visaCounsellorEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getVisaCounsellorEditProfileUrl(this.currentUser.id),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  writerEditProfile(data) {
    return this.http
      .put(this.apiRoutes.getWriterEditProfileUrl(this.currentUser.id), data)
      .map((res: any) => {
        return res;
      });
  }

  writerManagerEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getWriterManagerEditProfileUrl(this.currentUser.id),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  counsellingManagerEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getCounsellingManagerEditProfileUrl(this.currentUser.id),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  visaCounsellingManagerEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getVisaCounsellingManagerEditProfileUrl(
          this.currentUser.id
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  interviewTrainerManagerEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getInterviewTrainerManagerEditProfileUrl(
          this.currentUser.id
        ),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  interviewTrainerEditProfile(data) {
    return this.http
      .put(
        this.apiRoutes.getInterviewTrainerEditProfileUrl(this.currentUser.id),
        data
      )
      .map((res: any) => {
        return res;
      });
  }

  getChats(params = {}) {
    let url =
      this.apiRoutes.getChatsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  exportChat(groupId) {
    return this.http
      .get(this.apiRoutes.getExportChatsUrl(this.currentUser.slug, groupId))
      .map((res: any) => {
        return res;
      });
  }

  getUser(userId) {
    return this.http.get(this.apiRoutes.getUserUrl(userId)).map((res: any) => {
      return res;
    });
  }

  updateUser(userId, data) {
    return this.http
      .put(this.apiRoutes.getUserUrl(userId), data)
      .map((res: any) => {
        return res;
      });
  }

  getStudentNotifications(studentId, params = {}) {
    let url =
      this.apiRoutes.getStudentNotificationsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getMentorNotification(studentSlug, params = {}) {
    let url =
      this.apiRoutes.getMentorNotification(this.currentUser.id, studentSlug) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudentNotifications(studentId, params = {}) {
    let url =
      this.apiRoutes.getCounsellorStudentNotificationsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudentNotifications(studentId, params = {}) {
    let url =
      this.apiRoutes.getInterviewTrainerStudentNotificationsUrl(
        this.currentUser.id,
        studentId
      ) + Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getNotifications(params = {}) {
    let url =
      this.apiRoutes.getNotificationsUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  readNotifications(notificationIds) {
    let url = this.apiRoutes.getNotificationsUrl(this.currentUser.id);
    return this.http.put(url, notificationIds).map((res: any) => {
      return res;
    });
  }

  getCounsellorStudents(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getAssignWriterCounsellorStudent(studentId) {
    let url = this.apiRoutes.getAssignWriterCounsellorWriter(
      this.currentUser.id,
      studentId
    );
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellorStudents(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForVisaCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerSessions(params = {}) {
    let url =
      this.apiRoutes.getAllSessionsForInterviewTrainerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getVisaCounsellingSessions(params = {}) {
    let url =
      this.apiRoutes.getAllSessionsForVisaCounsellorUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getInterviewTrainerStudents(params = {}) {
    let url =
      this.apiRoutes.getAllStudentsForInterviewTrainerUrl(this.currentUser.id) +
      Helper.getQueryParamsString(params);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  postBroadcastMessage(formData) {
    let url = this.apiRoutes.getBroadcastMessageUrl(this.currentUser.slug);
    return this.http.post(url, formData).map((res: any) => {
      return res;
    });
  }

  getAllAppointments(params) {
    let url =
      this.apiRoutes.getAllAppointmentUrl() +
      Helper.getQueryParamsString(params);
    console.log("URL", url);
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getUserRole(userId){
    let url = this.apiRoutes.getUserRoles(userId) 
    return this.http.get(url).map((res: any) => {
      return res;
    });
  }

  getConfirmAppointmentMentorSlot(appointmentId, data) {
    let url = this.apiRoutes.getConfirmAppointmentMentorSlot(
      appointmentId,
      this.currentUser.id
    );
    return this.http.patch(url, data).map((res: any) => {
      return res;
    });
  }

  setDefaultCollege(){
    let url = this.apiRoutes.setDefaultCollegeUrl(
      this.currentUser.id
    );
    return this.http.patch(url,'').map((res: any) => {
      return res;
    });
  }

  getConfirmAppointmentCounsellorSlot(appointmentId, data) {
    let url = this.apiRoutes.getConfirmAppointmentCounsellorSlot(
      appointmentId,
      this.currentUser.id
    );
    return this.http.patch(url, data).map((res: any) => {
      return res;
    });
  }


  updateUserRoleTable(){
    let url = this.apiRoutes.updateUserRolesTable();
    return this.http.post(url,'').map((res: any) => {
      return res;
    });
  }

  updateRoadmapMetaData() {
    let url = this.apiRoutes.updateRoadmapMeta();
    return this.http.post(url,'').map((res: any) => {
      return res;
    });
  }



}
