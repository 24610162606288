import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService, ModalOptions } from "ngx-bootstrap";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { UserService } from "src/app/core/services/user.services";
import { Toastr } from "src/app/core/plugins/toastr/toastr";
import { Helper } from "src/app/core/utilities/helpers";
@Component({
  selector: "app-slot-selection",
  templateUrl: "./slot-selection.component.html",
  styleUrls: ["./slot-selection.component.css"],
  providers: [UserService],
})
export class SlotSelectionModal implements OnInit {
  // @ViewChild("dp1") datePicker: any;

  public slotTimingForm: FormGroup;
  public submitted: boolean = false;
  public initialState: any;
  public slot1: any;
  public slot2: any;
  public slot3: any;
  public appointmentId: any;
  public isMentor: any;
  public meetingLink: string;
  public pref_slot_1: string;
  public pref_slot_2: string;
  public pref_slot_3: string;
  public meetingPassword: string;
  public customValue: boolean;

  constructor(
    public modalRef: BsModalRef,
    public options: ModalOptions,
    public formBuilder: FormBuilder,
    public userService: UserService,
    public toastr: Toastr
  ) {
    this._initSlotTimingForm();
    this.initialState = this.options.initialState;
    // console.log("time", this.initialState);
    this._initTime();
  }

  _initTime() {
    this.customValue = false;
    this.slot1 = moment(this.initialState.data.pref_slot_1).format(
      "DD-MM-YYYY hh:mm A"
    );
    this.slot2 = moment(this.initialState.data.pref_slot_2).format(
      "DD-MM-YYYY hh:mm A"
    );
    this.slot3 = moment(this.initialState.data.pref_slot_3).format(
      "DD-MM-YYYY hh:mm A"
    );
    this.pref_slot_1 = this.initialState.data.pref_slot_1;
    this.pref_slot_2 = this.initialState.data.pref_slot_2;
    this.pref_slot_3 = this.initialState.data.pref_slot_3;
    this.appointmentId = this.initialState.data.id;
    this.isMentor = this.initialState.mentor;
    this.meetingLink = this.initialState.meetingLink;
    this.meetingPassword = this.initialState.meetingPassword;

    console.log("meeting data", this.initialState);
  }

  ngOnInit() {}

  _initSlotTimingForm() {
    this.slotTimingForm = this.formBuilder.group({
      selected_slot: [""],
      meeting_link: [{ value: this.meetingLink, disabled: true }],
      password: [{ value: this.meetingPassword, disabled: true }],
      date: [""],
      time: [""],
    });
  }

  submitSlotTiming() {
    console.log(
      "============+>",
      this.slotTimingForm,
      this.customValue,
      this.isMentor
    );
    let selected_slot = this.slotTimingForm.value.selected_slot;

    if (!this.isMentor) {
      if (this.slotTimingForm.invalid) {
        console.log("------>12");

        return;
      }
      if (
        !this.customValue &&
        this.slotTimingForm.value.selected_slot.length === 0
      ) {
        console.log("------>1");
        return;
      }
      if (
        this.customValue &&
        (this.slotTimingForm.value.date.length === 0 ||
          this.slotTimingForm.value.time.length === 0)
      ) {
        console.log("------>2");
        return;
      }
      if (this.customValue) {
        let formData = Helper.cloneObj(this.slotTimingForm.value, {
          date: moment(this.slotTimingForm.value.date).format("YYYY-MM-DD"),
          time: moment(this.slotTimingForm.value.time).format("HH:mm"),
        });
        selected_slot = formData.date + " " + formData.time;
      }
    }

    // console.log("============+>", this.slotTimingForm.value, this.customValue);
    console.log("============+>", this.slotTimingForm.value.selected_slot);

    this.slotTimingForm.patchValue({
      selected_slot: selected_slot,
      meeting_link: this.meetingLink,
      password: this.meetingPassword,
    });
    let submitFormData = {
      selected_slot: selected_slot,
      meeting_link: this.meetingLink,
      password: this.meetingPassword,
    };

    if (this.isMentor) {
      this.userService
        .getConfirmAppointmentMentorSlot(this.appointmentId, submitFormData)
        .subscribe((res) => {
          this.toastr.showSuccess("Slot confirmed");
          // console.log("response mentor", res);
        });
      // console.log("Is mentor true");
    } else {
      this.userService
        .getConfirmAppointmentCounsellorSlot(this.appointmentId, submitFormData)
        .subscribe((res) => {
          this.toastr.showSuccess("Slot confirmed");
          // console.log("response counsellor", res);
        });
    }
    console.log("Slot timting gorm ", this.slotTimingForm.value);

    this.modalRef.hide();
  }
  updateSlotOptions(event) {
    this.customValue = event.target.checked;
  }
}
