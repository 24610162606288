import { Injectable, ReflectiveInjector } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import * as strtr from "locutus/php/strings/strtr";

@Injectable()
export class Helper {
  static processErrorResponse(error: any, toastr: any) {
    let error_messages;
    if (error.error) {
      error_messages = Helper.getTransformedErrorResponse(error.error);
    } else {
      error_messages = Helper.getTransformedErrorResponse(error);
    }

    if (error_messages && error_messages.length > 0) {
      toastr.showError(error_messages[0]);
    } else {
      toastr.showError();
    }
  }

  static getQueryParamsString(paramsObj = {}) {
    let parts = [];
    for (var i in paramsObj) {
      if (paramsObj.hasOwnProperty(i)) {
        parts.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(paramsObj[i])
        );
      }
    }
    return "?" + parts.join("&");
  }

  static getTransformedErrorResponse(json_response: any) {
    // In case of ErrorObservable
    // console.log('json_response-----> ', json_response);
    // if(
    //   json_response.constructor.name == "ErrorObservable" ||
    //   json_response.constructor.name === "Response" ||
    //   json_response.constructor.name === "e"
    // ) {
    //   if(json_response.hasOwnProperty('_body') && ! this.isEmpty(json_response['_body'])) {
    //     json_response = JSON.parse(json_response._body);
    //   }
    //   // json_response = json_response.errors;
    // }

    if (!json_response) {
      return null;
    }

    if (Helper.hasValidationErrors(json_response)) {
      return Helper.getValidationErrorObj(json_response);
    } else if (
      typeof json_response === "object" &&
      json_response.hasOwnProperty("message")
    ) {
      return [json_response.message];
    } else if (typeof json_response === "string") {
      return [JSON.parse(json_response).message];
    }
    return null;
  }

  static hasValidationErrors(error): boolean {
    if (error.code == 422) {
      return true;
    }
    return false;
  }

  /**
   * Get Translated String for the provided one
   * @param {string}  string
   * @param {array}   attributes
   */
  static getTranslatedStr(string, attributes = {}) {
    if (_.isString(string)) {
      return strtr(string, attributes);
    }
    return string;
  }

  static getValidationErrorObj(error) {
    let errorObj: any;
    errorObj = error;
    // if(Object.keys(error).length !== 0 && error.constructor === Object) {
    //   errorObj = error
    // } else {
    //   errorObj = error.json();
    // }
    let error_messages = [];
    if (errorObj.errors) {
      let errorMessagesObj = errorObj.errors;
      for (let key in errorMessagesObj) {
        error_messages.push(errorMessagesObj[key]);
      }
    }
    return error_messages.length > 0
      ? error_messages
      : ["Something went wrong, try again later!"];
  }

  static isEmpty(value) {
    let bool =
      value == null ||
      (typeof value === "string" && value.length === 0) ||
      (Helper.isArray(value) && value.length === 0);

    if (typeof value === "object") {
      for (let key in value) {
        if (value.hasOwnProperty(key)) return false;
      }
      return true;
    }

    return bool;
  }

  static isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  static isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static isArray(value) {
    return Object.prototype.toString.call(value) === "[object Array]";
  }

  static isNull(value) {
    return _.isNull(value);
  }

  static ucfirst(str: string) {
    return str.replace(str[0], str[0].toUpperCase());
  }

  static objectExcept(obj, keys) {
    let target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;

      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;

      target[i] = obj[i];
    }

    return target;
  }

  static getObjProp(
    obj: Object,
    dotNotationStr: string,
    defaultVal: any = null
  ) {
    return _.get(obj, dotNotationStr, defaultVal);
  }

  static convertKeyToText(key) {
    const wordsArray = key.split("_");
    const text =
      wordsArray.length > 0
        ? wordsArray.map((word) => Helper.ucfirst(word)).join(" ")
        : Helper.ucfirst(key);
    return text;
  }

  static convertTextToKey(text) {
    const wordsArray = text.split(" ");
    const key =
      wordsArray.length > 0
        ? wordsArray.map((word) => Helper.lcfirst(word)).join("_")
        : Helper.lcfirst(text);
    return key;
  }
  // convert first character of string to lowercase
  static lcfirst(str: string) {
    return str.replace(str[0], str[0].toLowerCase());
  }

  static formatTimestamp(timestamp: string) {
    // let date, time;
    // [date, time] = timestamp.split(', ');
    // time = time.substring(0, time.lastIndexOf(':'));
    // time = this.convertTimeTo24Hr(time);
    // return `${date} ${time}`;
    let formattedTimestamp = moment().format("DD/MM/YYYY, hh:mm A");
    return formattedTimestamp;
  }

  static convertTimeTo24Hr(time12Hr: any) {
    // Check correct time format and split into components
    time12Hr = time12Hr
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time12Hr];

    if (time12Hr.length > 1) {
      // If time format correct
      time12Hr = time12Hr.slice(1); // Remove full string match value
      time12Hr[5] = +time12Hr[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time12Hr[0] = +time12Hr[0] % 12 || 12; // Adjust hours
    }
    return time12Hr.join(""); // return adjusted time or original string
  }

  static cloneObj(obj: Object, ...sources) {
    return _.assign({}, obj, ...sources);
  }

  static arrayDifference(firstArray, secondArray) {
    return _.difference(firstArray, secondArray);
  }

  // Remove N/A fill null
  static setObjProp(
    obj: Object,
    dotNotationStr: string,
    defaultVal: any = null
  ) {
    let value = _.property(dotNotationStr)(obj);
    if (value == "") {
      return null;
    }
    return value == "N/A" ? defaultVal : value;
  }

  // it return that object array which has given id
  static searchObjById(obj, id) {
    console.log(obj, id);
    return obj.filter((item) => item.id === id);
  }
  // from MM/DD/YYYY to YYYY-MM-DD
  static changeDateFormat(obj) {
    const dateFields = ["deadline"];

    const formValues = _.mapValues(obj, (val, key) => {
      if (dateFields.indexOf(key) != -1 && val) {
        return moment(val).format("l");
      }
      return val;
    });
    return formValues.deadline;
  }

  static _changeSelectorText(selector, beforeMessage, afterMessage) {
    const ele = document.querySelectorAll(selector);
    Array.from(ele).forEach((e) => {
      if (e.innerHTML === beforeMessage) {
        e.innerHTML = afterMessage;
      }
    });
  }

  static onRoleSelection(
    selectedValue: string,
    allRoles: any[],
    modalRef: any,
    router: any,
    toastr: any
  ) {
    const tempLogin = JSON.parse(localStorage.getItem("authUser"));
    const selectedRole = allRoles.find((role) => role.role_id == selectedValue);

    const userData = {
      ...tempLogin,
      role_id: selectedRole.role_id,
      is_admin: false,
      is_counsellor: false,
      is_counsellor_manager: false,
      is_interview_manager: false,
      is_interview_trainer: false,
      is_mentor: false,
      is_student: false,
      is_visa_counsellor: false,
      is_visa_counsellor_manager: false,
      is_writer: false,
      is_writer_manager: false,
      [`is_${selectedRole.type.replaceAll("-", "_")}`]: true,
      type: selectedRole.type,
    };

    localStorage.setItem("authUser", JSON.stringify(userData));
    modalRef.hide();
    this.navigateToRoute(userData, router, toastr);
  }

  static navigateToRoute(authUser: any, router: any, toastr: any) {
    if (authUser.is_admin) {
      router.navigate(["/manager"]);
    } else if (authUser.is_counsellor) {
      router.navigate(["/counsellor"]);
    } else if (authUser.is_counsellor_manager) {
      router.navigate(["/counselling-manager"]);
    } else if (authUser.is_writer) {
      router.navigate(["/writer"]);
    } else if (authUser.is_writer_manager) {
      router.navigate(["/writer-manager"]);
    } else if (authUser.is_student) {
      toastr.showWarning("You are not allowed to log in from this app");
    } else if (authUser.is_interview_manager) {
      router.navigate(["/interview-trainer-manager"]);
    } else if (authUser.is_interview_trainer) {
      router.navigate(["/interview-trainer"]);
    } else if (authUser.is_visa_counsellor_manager) {
      router.navigate(["/visa-counsellor-manager"]);
    } else if (authUser.is_visa_counsellor) {
      router.navigate(["/visa-counsellor"]);
    } else if (authUser.is_mentor) {
      router.navigate(["/mentor"]);
    }
    window.location.reload();
  }

  static formatType(type) {
    return type
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  }
}
